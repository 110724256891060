import React from 'react';
import './Header.css';

const Header = () => {

    return (
        <header className={'Header'}>
            <div className={'LogoContainer'}>
                <a rel={'noreferrer'} className={'Social'} href={'/'} aria-label="Логотип">
                    <img className={'Logo'} alt={'Логотип'} src={'./logo192.png'}/>
                </a>
                <span className={'Brand'}>
                    Baidarki Grodno
                </span>
            </div>
            <nav>
                <a className={'Navigation'} aria-label="Забронировать байдарку" href={'#Contacts'} rel={'noreferrer'}>
                    Забронировать
                </a>
            </nav>
        </header>
    );
};

export default Header;
