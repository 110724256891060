import React, {FC, useEffect, useState} from 'react';
import classNames from "classnames";
import './MainContent.css';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/effect-creative";
// Import Swiper styles
import "swiper/css/pagination"

SwiperCore.use([EffectFade, Autoplay]);

interface MainContentProps {

}

const MainContent: FC<MainContentProps> = ({

                                           }) => {
    const [isReady, setIsReady] = useState(false);
    const [bookReady, setBookReady] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);


    useEffect(() => {
        setTimeout(() => {
            setIsReady(true)
            setTimeout(() => {
                setBookReady(true)
            }, 900)
        }, 300)
    }, [])

    return (
        <article className={'MainContentWrapper'}>
            <div className='SwiperContainerMain'>
            <Swiper noSwiping allowTouchMove={false} direction={'vertical'} speed={700}
                    className={`swiper-container full-height-swiper full-width`} loop
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}>
                <SwiperSlide>
                    <img src={'./main_bh.webp'} alt={'kayak'}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={'./main_sup.webp'} alt={'sup board'}/>
                </SwiperSlide>
            </Swiper>
            </div>
            <div className={'MainContent'}>
                <h1 className={classNames('Heading')}>
                    Аренда байдарок и сапбордов в Гродно
                </h1>
                {
                    <div className={classNames({animate__fadeInUp: isReady}, 'animate__animated', 'Description')}
                         style={{
                             visibility: isReady ? 'unset' : 'hidden'
                         }}>
                        <span>Хотите незабываемо отдохнуть и провести время? Вы по адресу! Лучшие байдарки и сапы Гродно ждут тебя</span>

                    </div>
                }
                <a rel={'noreferrer'} role={'button'} href={'#About'}
                        aria-label="Ознакомиться подробнее"
                        className={classNames({animate__fadeInDown: bookReady}, 'animate__animated', 'BookButton')}
                        style={{
                            visibility: bookReady  ? 'unset' : 'hidden'
                        }}>
                    Подробнее
                </a>
            </div>
        </article>
    );
};

export default MainContent;
