import React from 'react';
import './Footer.css';
import Instagram from "./Instagram";

const Footer = () => {
    return (
        <footer className={'Footer'}>
            <div className={'Socials'}>
                <a rel={'noreferrer'} target={'_blank'}
                   aria-label="Перейти в instagram"
                   className={'Social'} href={'https://www.instagram.com/baidarkigrodno.by/'}>
                    <Instagram />
                </a>
            </div>
            <div className={'Copyright'}>
                © 2022-2024 enemyxxx | All Rights Reserved
            </div>
        </footer>
    );
};

export default Footer;
